import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private lightThemeName: string = "assets/styles/saga-blue/theme.css";
  private darkThemeName: string = "assets/styles/arya-blue/theme.css";
  isDarkTheme = false;
  storageName: string = 'darkTheme'

  toggleTheme() {
    let themeElement = document.getElementById('theme-link');
    if (themeElement) {
      console.log(themeElement.getAttribute('href'));
      themeElement.setAttribute('href', this.isDarkTheme ? this.lightThemeName : this.darkThemeName);
    }
    this.isDarkTheme = !this.isDarkTheme;
    localStorage.setItem(this.storageName, String(this.isDarkTheme));
  }

  loadStorageTheme() {
    const isDarkTheme = localStorage.getItem(this.storageName);
    if (isDarkTheme === 'true') {
      this.isDarkTheme = true;
      let themeElement = document.getElementById('theme-link');
      if (themeElement) {
        console.log(themeElement.getAttribute('href'));
        themeElement.setAttribute('href', this.darkThemeName);
      }
    }

  }
}
