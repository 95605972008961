import {Component, OnInit} from '@angular/core';
import {MenuItem} from "primeng/api";
import {ThemeService} from "../services/theme.service";
import {Observable} from "rxjs";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  items: MenuItem[] = [];
  display = false;

  constructor(public themeService: ThemeService) {
  }


  ngOnInit(): void {
    // this.items = [
    //   {
    //     label: 'File',
    //     items: [{
    //       label: 'New',
    //       icon: 'pi pi-fw pi-plus',
    //       items: [
    //         {label: 'Project'},
    //         {label: 'Other'},
    //       ]
    //     },
    //       {label: 'Open'},
    //       {label: 'Quit'}
    //     ]
    //   },
    //   {
    //     label: 'Edit',
    //     icon: 'pi pi-fw pi-pencil',
    //     items: [
    //       {label: 'Delete', icon: 'pi pi-fw pi-trash'},
    //       {label: 'Refresh', icon: 'pi pi-fw pi-refresh'}
    //     ]
    //   }
    // ];
  }

  toggleTheme() {
    this.themeService.toggleTheme();

  }
}
