<div class="header">
  <p-menubar [autoDisplay]="false">
    <ng-template pTemplate="start">
      <strong>JNDMNY</strong>
    </ng-template>
    <ng-template pTemplate="end">
      <div class="p-d-flex">
        <p class="p-mr-1">MENU</p>
        <img src="assets/menu-fill.svg" height="40" class="p-mr-2  p-as-center" (click)="display = true">
      </div>
    </ng-template>
  </p-menubar>
</div>
<p-sidebar [(visible)]="display" position="right">
  <img [src]="themeService.isDarkTheme ? 'assets/lamp-yellow.svg' : 'assets/lamp-black.svg'" height="20"
       (click)="toggleTheme()">
  <div class="p-d-flex p-jc-center">
    <h1 style="font-weight:normal">Menu</h1>
  </div>
</p-sidebar>
